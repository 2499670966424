<script setup lang="ts">
import { useEventListener } from '@vueuse/core'
import { PlayerConsentResponseType } from '@core/types/clients'
import { isEmpty } from 'lodash-es'

const route = useRoute()
const { env, webauthn } = useCoreConfig()
const { isLoggedIn, user, userJustLoggedIn } = useAuthSync()
const { consentCode } = useRecurringConsent()
const { getConsent } = useConsent()
const flags = useFeatureFlags()
const { getPendingReward, pendingReward } = useRewards()
const dialogContext = useDialogContext()

onMounted(() => {
    initWebauthn()
    initiatePassKeyLogin()
})

// AuthContainer
const authContainer = computed(() => {
    return {
        enabled: !!route.query.auth
    }
})

// PromotionTermsContainer
const promotionTermsContainer = computed(() => {
    const context: {
        terms: boolean
        privacyPolicy: boolean
        promotionId?: number
    } = {
        terms: false,
        promotionId: undefined,
        privacyPolicy: false
    }

    if (!!route.query.terms || !!route.query['general-terms']) {
        context.terms = true

        const promotionId = Number(route.query.promotionId)

        if (promotionId) {
            context.promotionId = promotionId
        }
    }
    else if (route.query['privacy-policy']) {
        context.privacyPolicy = true
    }

    const enabled = !!context.promotionId || !!context.terms || !!context.privacyPolicy

    return {
        context,
        enabled
    }
})

// Promotion LoginStreak
const { streakCookie, getLoginStreak } = useLoginStreak()
const { clock } = useClock()

const loginStreak = ref(0)
const hasCalledLoginStreak = ref(false)

function isDateOlderThanToday(dateString: string) {
    const date = new Date(dateString)
    const today = new Date(clock.value)

    return date < new Date(today.getFullYear(), today.getMonth(), today.getDate())
}

const loginStreakModalEnabled = computed(() => {
    if (import.meta.server || !flags?.enableLoginStreak || !isLoggedIn.value) return false
    if (Number(user?.value?.id ?? 2) % 2 !== 0) return false // Only users with even user_id's are eligible

    // If streakCookie is set, determine eligibility based on cookie date
    if (streakCookie.value) {
        const isEligible = isDateOlderThanToday(streakCookie.value)

        return isEligible
    }

    // Default to true if no streakCookie is set
    return true
})

const enableStreakQuery = computed(() => (
    loginStreakModalEnabled.value
    && isEmpty(pendingReward.value?.Promotion)
    && !hasCalledLoginStreak.value
))

const streakQuery = getLoginStreak({ enabled: enableStreakQuery.value })

watch(loginStreakModalEnabled, (isEligible) => {
    if (streakCookie.value && isEligible) {
        hasCalledLoginStreak.value = false
    }
}, {
    immediate: true
})

whenever(() => route.path, () => {
    if (loginStreakModalEnabled.value && !hasCalledLoginStreak.value) {
        streakQuery.refresh()
    }
})

whenever(streakQuery.data, (streak) => { // Update loginStreak value
    hasCalledLoginStreak.value = true
    loginStreak.value = Number(streak)

    if (loginStreak.value === 3) {
        getPendingReward()
    }
}, {
    immediate: true
})

// PaymentsReceipt
const paymentsReceiptEnabled = ref<boolean>(false)

whenever(() => !!isLoggedIn.value && !!route.query.txId, () => {
    paymentsReceiptEnabled.value = true
}, {
    immediate: true
})

// UIPassKeyModal
const { passkey_status, isMobileApp, isPlatformPassKeySupported, getAuthenticationOptions, authenticateWithWebAuthn, startAuthentication, init: initWebauthn } = useWebauthn()

async function initiatePassKeyLogin() {
    if (!webauthn?.enabled) return
    // Remove this check when we wish to enable pass keys on the website
    if (!isMobileApp) return
    if (passkey_status.value !== 'registered') return
    if (isLoggedIn.value) return

    try {
        const authenticationOptions = await getAuthenticationOptions()

        // Keep this for when we need it on the website
        if (isMobileApp) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'pass_keys_authenticate_with_pass_key', authenticationOptions }))

            return
        }

        if (!isPlatformPassKeySupported()) return

        startAuthentication(authenticationOptions).then(authenticateWithWebAuthn)
    }
    catch {
        // Do nothing
    }
}

// ConsentChangedDialog
const { getGeneralTerms, getPrivacyPolicy } = useConsentContent()
const consentChangedConsents = getConsent({ lazy: true, server: false, enabled: isLoggedIn })
const consentChangedGeneralTermsQuery = getGeneralTerms({ enabled: isLoggedIn, lazy: true, server: false })
const consentChangedPrivacyPolicyQuery = getPrivacyPolicy({ enabled: isLoggedIn, lazy: true, server: false })
const consentChangedAcceptedVersions = computed<Record<'general-terms' | 'privacy-policy', number>>(() => {
    if (!consentChangedConsents.data.value) return {}

    const versions: Record<string, number> = {}

    for (const consent of consentChangedConsents.data.value) {
        if (consent.type === PlayerConsentResponseType.Value1) {
            versions['general-terms'] = consent.version
        }
        if (consent.type === PlayerConsentResponseType.Value2) {
            versions['privacy-policy'] = consent.version
        }
    }

    return versions
})
const consentChanged = computed(() => {
    const showGeneralTerms = !!consentChangedGeneralTermsQuery.data.value && !!consentChangedAcceptedVersions.value['general-terms'] && consentChangedAcceptedVersions.value['general-terms'] < consentChangedGeneralTermsQuery.data.value.version
    const showPrivacyPolicy = !!consentChangedPrivacyPolicyQuery.data.value && !!consentChangedAcceptedVersions.value['privacy-policy'] && consentChangedAcceptedVersions.value['privacy-policy'] < consentChangedPrivacyPolicyQuery.data.value.version

    return {
        enabled: showGeneralTerms || showPrivacyPolicy,
        showGeneralTerms,
        showPrivacyPolicy
    }
})

useEventListener(window, 'beforeunload', () => {
    dialogContext.togglePageScrollLock()
})
</script>

<template>
    <ClientOnly>
        <!-- Dialogs -->
        <LazyConsentRecurringConsent v-if="userJustLoggedIn || (isLoggedIn && !!consentCode)" />
        <LazyAuthContainer v-if="authContainer.enabled" />
        <LazyPromotionTermsContainer
            v-if="promotionTermsContainer.enabled"
            :context="promotionTermsContainer.context"
        />

        <!-- Login streak modal -->
        <LazyPromotionLoginStreak
            v-if="loginStreak > 0 && loginStreak < 3"
            :streak="loginStreak"
        />

        <!-- z-index refers to take-over -->
        <LazyPaymentsReceipt
            v-if="paymentsReceiptEnabled"
            z-index="21"
        />
        <LazyUIPassKeysModal v-if="webauthn?.enabled && isMobileApp && isLoggedIn" />
        <LazyConsentChangedDialog
            v-if="consentChanged.enabled"
            :show-general-terms="consentChanged.showGeneralTerms"
            :show-privacy-policy="consentChanged.showPrivacyPolicy"
            :general-terms-query="consentChangedGeneralTermsQuery"
            :privacy-policy-query="consentChangedPrivacyPolicyQuery"
            :accepted-versions="consentChangedAcceptedVersions"
            @consent:refresh="consentChangedConsents.refresh()"
        />
        <LazyUIEnvBanner v-if="env !== 'prod'" />
    </ClientOnly>
</template>
